/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"
import {
  MdPhoneIphone,
  MdHiking,
  MdLocationPin,
  MdOutlineIntegrationInstructions,
  MdQrCode,
} from "react-icons/md"
import { FaHome, FaMap, FaHiking, FaUserPlus } from "react-icons/fa"

import { Layout, Seo } from "../../layout"
import { FindOutMoreButton, IPhone, img } from "../../components"

export default function IndexPage(props) {
  const iphone = "hidden sm:block flex-none w-40 ml-16 mr-4 mt-4"
  const product = "sm:my-12 sm:px-8 py-6 rounded max-w-5xl mx-auto"
  const product_name =
    "flex justify-left items-center mb-4 text-base sm:text-lg font-bold"
  const paragraph = "my-6"
  const table = "mt-4 mb-2 table-auto"
  const cell =
    "px-3 sm:px-6 py-1 border border-gray-400 bg-gray-200 text-sm sm:text-base"

  return (
    <Layout path={props.path}>
      <Seo title="Products" />

      <div className="mt-12 p-6 md:p-16">
        <div id="app" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdPhoneIphone className="w-full h-full" />
              </Icon>
              <div>The Trailguide App</div>
            </div>
            <p className={paragraph}>
              Trailguide is an app to share, find, and navigate outdoor
              activities. The content is crowd-sourced by the users and it has a
              lot of content all over Europe. The publicly available content
              consists of biking activities.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Trailguide App</td>
                  <td className={cell}>Free</td>
                </tr>
              </tbody>
            </table>

            <br />
            <MoreButton to="/products/app" className="mr-4">
              Find out more
            </MoreButton>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("/website/trailguide-iphone-world-4.jpeg")}
            className={iphone}
          />
        </div>

        <div id="white-label" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdHiking className="w-full h-full" />
              </Icon>
              <div>Custom White-Label App</div>
            </div>
            <p className={paragraph}>
              We offer custom white-label tour apps for your resort or
              destination. Maintain total control of the content you present to
              your guests and visitors having your own tour app. The content you
              present to your guests will be limited to relevant activities
              only.
            </p>

            <MoreButton to="/products/whitelabel" className="mr-4">
              Find out more
            </MoreButton>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("website/trailguide-iphone-sandbox-icons.jpg")}
            className={iphone}
          />
        </div>

        <div id="integration" className={product + " flex items-start"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdOutlineIntegrationInstructions className="w-full h-full" />
              </Icon>
              <div>Trailguide Integration</div>
            </div>
            <p className={paragraph}>
              Integrate your content into your websites directly from the
              Trailguide database. The plugin is included by adding a very
              simple piece of code unique to your domain. It is super fast and
              light weight and very flexible.
            </p>
            <p className={paragraph}>
              The plugin can display content as a map or as a set of cards.
              Clicking on an activity will open a window showing all available
              information about the tour, including images, videos, reviews and
              condition reports.
            </p>
            <p className={paragraph}>
              The map can be extended to full screen and the visitor can open
              activities directly in the trailguide app.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Trailguide Integration</td>
                  <td className={cell}>€500 per domain/year</td>
                </tr>
              </tbody>
            </table>
            <MoreButton to="/products/plugin">Find out more</MoreButton>
          </div>
          <div className="hidden sm:block ml-8 mt-16">
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 mb-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
 license="your_license_key"
 content="8064,6985,6816,8173"
 mode="carousel">
</trailguide-plugin>
`.trim()}
            </pre>
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
   license="your_license_key"
   content="7783,8064"
   lang="no"
   mode="full">
</trailguide-plugin>
  `.trim()}
            </pre>
          </div>
        </div>

        <div id="presence" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdLocationPin className="w-full h-full" />
              </Icon>
              <div>Presence in the Trailguide app</div>
            </div>
            <p className={paragraph}>
              If you are a tourist region, destination, resort, or another local
              service that offers services that visitors and guests might need,
              you can have your business added to the map on Trailguide and in
              tour descriptions.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Local service</td>
                  <td className={cell}>€400 per year</td>
                </tr>
                <tr>
                  <td className={cell}>Resort</td>
                  <td className={cell}>€800 per year</td>
                </tr>
                <tr>
                  <td className={cell}>Destination</td>
                  <td className={cell}>€1500 per year</td>
                </tr>
                <tr>
                  <td className={cell}>Region</td>
                  <td className={cell}>€3000 per year</td>
                </tr>
              </tbody>
            </table>
            <MoreButton to="/products/presence">Find out more</MoreButton>
          </div>
          <IPhone
            alt="Ensure your presence on Trailguide"
            src={img("/website/trailguide-iphone-presence2.png")}
            className={iphone}
          />
        </div>

        <div id="landing_page" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <FaHome className="w-full h-full" />
              </Icon>
              <div>Trailguide Landing Page</div>
            </div>
            <p className={paragraph}>
              A landing page in the Trailguide app will show information about
              your region, destination, or resort along with available tours and
              activities. It can be opened directly through a link, scanning a
              QR-code, or clicking your icon on the Trailguide map.
            </p>
            <p className={paragraph}>
              If you are a tourist region or destination you can also show
              available local services such as hotels, campsites, sports shops,
              guide companies, shuttle companies, restaurants and bars in your
              landing page.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Landing Page</td>
                  <td className={cell}>€500 per year</td>
                </tr>
                <tr>
                  <td className={cell}>Local service in landing page</td>
                  <td className={cell}>€100 per business/year</td>
                </tr>
              </tbody>
            </table>
            <MoreButton to="/products/landingpage">Find out more</MoreButton>
          </div>
          <IPhone
            alt="Your own landing page on Trailguide"
            src={img("/website/trailguide-iphone-landingpage2.jpeg")}
            className={iphone}
          />
        </div>

        <div id="wall_map" className={product + " flex items-start"}>
          <div>
            <div className={product_name}>
              <Icon>
                <FaMap className="w-full h-full" />
              </Icon>
              <div>Printed Wall Maps</div>
            </div>
            <p className={paragraph}>
              We design and print 3D-like topographical maps you display in your
              reception area or shop. The maps have your unique QR-code on it
              which will open your Trailguide landing page when scanned.
            </p>
            <p className={paragraph}>
              We can also supply you with stickers with your landing page
              QR-code for you to place anywhere you want on your premises.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Custom wall mounted topo map</td>
                  <td className={cell}>€2000</td>
                </tr>
                <tr>
                  <td className={cell}>Additional print</td>
                  <td className={cell}>€400</td>
                </tr>
                <tr>
                  <td className={cell}>QR-code sticker pack</td>
                  <td className={cell}>Free</td>
                </tr>
              </tbody>
            </table>
          </div>
          <img
            className="hidden sm:block flex-none w-96 ml-16 mr-4 mt-16 rounded shadow"
            alt="Printed wall maps with QR codes"
            src={img("/website/trailguide-scan-map.jpg")}
          />
        </div>

        <div id="activity_manager" className={product}>
          <div className={product_name}>
            <Icon>
              <FaHiking className="w-full h-full" />
            </Icon>
            <div>Activity Manager</div>
          </div>
          <p className={paragraph}>
            Add any type of activity you want, not only biking activities. Have
            full control over the content you present to your guests and
            visitors. Hand pick the activities you want to present.
          </p>
          <p className={paragraph}>
            If you are an official tourist region, destination or a resort you
            can also brand activities with your official business name and logo
            instead of showing the user who added them.
          </p>
          <table className={table}>
            <tbody>
              <tr>
                <td className={cell}>Activity Manager module</td>
                <td className={cell}>€500 per year</td>
              </tr>
              <tr>
                <td className={cell}>Size of data set</td>
                <td className={cell}>€10 per activity/year</td>
              </tr>
              <tr>
                <td className={cell}>Branding of activities</td>
                <td className={cell}>€500 per year</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="collection_manager" className={product}>
          <div className={product_name}>
            <Icon>
              <MdQrCode className="w-full h-full" />
            </Icon>
            <div>Collection Manager</div>
          </div>
          <p className={paragraph}>
            Make your own collection of activities tailor made for different
            audiences and usages. Each of these collections can be accessed
            through scanning a QR-code, opening a link, or integrated into your
            web sites.
          </p>
          <table className={table}>
            <tbody>
              <tr>
                <td className={cell}>Collection Manager module</td>
                <td className={cell}>€500 per year</td>
              </tr>
            </tbody>
          </table>
          <MoreButton to="/pro/map">Find out more</MoreButton>
        </div>

        <div className={product}>
          <div className={product_name}>
            <Icon>
              <FaUserPlus className="w-full h-full" />
            </Icon>
            <div>Trailguide Admin User</div>
          </div>
          <p className={paragraph}>
            A license to the Trailguide portal comes with 1 admin user. You can
            purchase extra users for shared access and content creation and
            management.
          </p>
          <table className={table}>
            <tbody>
              <tr>
                <td className={cell}>Extra admin users</td>
                <td className={cell}>€200 per user/year</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

function Icon(props) {
  return (
    <div className="w-8 h-8 p-1 sm:w-10 sm:h-10 sm:p-2 mr-4 bg-blue-500 text-blue-100 rounded-full">
      {props.children}
    </div>
  )
}

function MoreButton(props) {
  return <FindOutMoreButton className="mt-8" {...props} />
}
